import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useCookies } from "react-cookie"
import classNames from "classnames"
import moment from "moment"

import "./Unread.scss"

const Unread = props => {
  
  const unreadCount = insights => {
    const { location } = props
    const [cookies, setCookie] = useCookies(["vaporware.net"])
    if(Object.keys(cookies).length === 0) return 0
    if(location && location.pathname === "/insights") {
      const now = moment()
      setCookie("insightsLastVisited", now, { path: "/"});
      return 0
    } else {
      const lastVisited = cookies.insightsLastVisited || 0 //datetimestamp of last visited
      const newInsights = insights.filter(insight => moment(insight.node.frontmatter.meta.date) > moment(lastVisited))
      return newInsights.length
    }
  }

  const data = useStaticQuery(graphql`
    query Insights {
      insights: allMarkdownRemark(
        filter: { frontmatter: { meta: { template: { in: ["post", "letter", "media"] }, published: { eq: true }, indexed: { ne: false } } } }
        sort: { fields: frontmatter___meta___date, order: DESC }
        limit: 9
      ) {
        cards: edges {
          node {
            ...InsightFragment
          }
        }
      }
    }
  `)

  const unread = unreadCount(data.insights.cards)
  const unreadClass = classNames("unread-insights bounce-animation", { unread })

  if(unread === 0) return <></>

  return (
    <div className={unreadClass}>{unread}</div>
  )
}

export default Unread
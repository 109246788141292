/* eslint-disable react/no-danger */
import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import remark from "remark"
import remarkHtml from "remark-html"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"

import BackgroundOverlay from "./BackgroundOverlay"
import "./PageHero.scss"

const PageHero = ({
  title,
  subtitle,
  description,
  logo,
  className,
  image,
  staticImage,
  imageCredit,
  imagePosition,
  heroTriangles,
  overlay,
}) => {
  const heroClass = classNames(
    "page-hero",
    className,
  )

  const pluginImage = getImage(image)

  const subtitleHTML = remark()
    .use(remarkHtml)
    .processSync(subtitle)
    .toString()

  const heroContent = (
    <>
      {overlay !== false && <BackgroundOverlay />}
      {heroTriangles &&
        <div>
          <div className="bg-top-right" />
          <div className="bg-bottom-left" />
        </div>
      }
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="content">
              {logo && (
                <div className="page-hero-logo">
                  <img src={logo} alt={title} />
                </div>
              )}
              {title && <h1>{title}</h1>}
              {subtitle && (
                <h2 dangerouslySetInnerHTML={{ __html: subtitleHTML }} />
              )}
              {description && (
                <p
                  className="description"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {imageCredit && imageCredit.name && imageCredit.link &&
        <a 
          href={imageCredit.link} 
          className="credit" 
          target="_blank"
          rel="noopener noreferrer"
        >
          photo by {imageCredit.name}
        </a>
      }
    </>
  )

  return <>
    {image ?
      <BgImage 
        image={pluginImage} 
        className={heroClass}
      >
        {heroContent}
      </BgImage>
      /* <BackgroundImage
        className={heroClass}
        fluid={image.childImageSharp.gatsbyImageData}
      >
        {heroContent}
      </BackgroundImage> */
    :
      <div
        className={heroClass}
        style={{
          backgroundPosition: imagePosition,
          backgroundImage: staticImage && `url(${staticImage})`,
        }}
      >
        {heroContent}
      </div>
    }
  </>;
}

PageHero.defaultProps = {
  title: undefined,
  subtitle: undefined,
  description: undefined,
  className: undefined,
  logo: undefined,
  staticImage: "",
  image: undefined,
  imagePosition: "center",
  heroTriangles: false,
  imageCredit: undefined,
  overlay: true,
}

PageHero.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string,
  logo: PropTypes.string,
  staticImage: PropTypes.string,
  image: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.object,
    }),
  }),
  imagePosition: PropTypes.string,
  heroTriangles: PropTypes.bool,
  imageCredit: PropTypes.shape({
    name: PropTypes.string,
    link: PropTypes.string,
  }),
  overlay: PropTypes.bool,
}

export default PageHero

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

import Header from "src/components/Header"
import Footer from "src/components/Footer"
import SEO from "src/components/SEO"
import "./Layout.scss"

const Layout = ({ meta: {title, image, description, path}, children, blueHeader, bodyClass, location}) => {
  
  const canonical = path ? `https://www.vaporware.net${path}` : location?.href
  
  return <>
    <Helmet
      bodyAttributes={{
        class: bodyClass,
      }}
    />
    <SEO
      title={title}
      image={image?.childImageSharp?.gatsbyImageData}
      description={description}
      canonical={canonical}
    />
    <Header isBlue={blueHeader} location={location} />
    <main>{children}</main>
    <Footer />
  </>;
}

Layout.defaultProps = {
  blueHeader: false,
  meta: {
    title: "",
    image: {
      childImageSharp: {
        gatsbyImageData: ""
      },
    },
    description: "",
  },
  bodyClass: undefined
}

Layout.propTypes = {
  meta: PropTypes.shape({
    description: PropTypes.string,
    title: PropTypes.string.isRequired,
    image: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        gatsbyImageData: PropTypes.string,
      }),
    }),
  }),
  children: PropTypes.node.isRequired,
  blueHeader: PropTypes.bool,
  bodyClass: PropTypes.string,
  location: PropTypes.object
}

export default Layout
import React, { useState } from "react"
import classNames from "classnames"
import { Link } from "gatsby"

import Logo from "src/components/Logo"
import Unread from "src/components/insights/Unread"

import "./Header.scss"

const Header = ({isBlue, location}) => {
  const [expanded, setExpanded] = useState(false)

  const navBarClass = classNames("navbar navbar-expand-lg navbar-dark", {
    blue: isBlue,
    expanded: expanded,
  })
  const toggleClass = classNames("navbar-toggler", { collapsed: !expanded })
  const menuClass = classNames("collapse navbar-collapse", { show: expanded })

  return (
    <header>
      <nav className={navBarClass}>
        <div className="container">
          <Logo className="navbar-brand" isBlue={isBlue} />
          <button
            className={toggleClass}
            onClick={() => setExpanded(!expanded)}
            type="button"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span />
            <span />
            <span />
            <span />
          </button>
          <div className={menuClass}>
            <ul className="navbar-nav ml-auto align-items-center">
              <li className="nav-item">
                <Link className="nav-link" to="/insights" id="navbarInsights">
                  Insights
                </Link>
                <Unread location={location} />
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/about">
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/chat" className="btn btn-secondary">
                  Let's talk
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Header

import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import overlay from "src/assets/images/hero-overlay.svg"
import "./BackgroundOverlay.scss"

const BackgroundOverlay = ({ hoverable }) => {
  const overlayClass = classNames("background-overlay", { hoverable })
  return <div 
    className={overlayClass}
    style={{ backgroundImage: `url(${overlay})` }}
  />
}

BackgroundOverlay.defaultProps = {
  hoverable: false,
}

BackgroundOverlay.propTypes = {
  hoverable: PropTypes.bool,
}

export default BackgroundOverlay

import React from "react"
import { Link } from "gatsby"

import Logo from "src/components/Logo"
import GetConsultationButton from "src/components/GetConsultationButton"
import github from "src/assets/images/github.svg"
import dribble from "src/assets/images/dribble.svg"
import twitter from "src/assets/images/twitter.svg"
import linkedin from "src/assets/images/linkedin.svg"
import "./Footer.scss"

const Footer = () => (
  <footer className="site-footer">
    <div className="bg-bottom-left" />
    <div className="container">
      <div className="row">
        <div className="col-lg-4 col-xl-3">
          <div className="logo">
            <Logo />
          </div>
        </div>
        <div className="main col-lg-8 offset-xl-1">
          <p>
            Since 2013, Vaporware® has partnered with innovative business leaders <br/>
            to create, reimagine, and scale better software products for their customers.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 col-xl-3">
          <div className="cta">
            <p>Ready for better?</p>
            <GetConsultationButton />
          </div>
        </div>
        <div className="main col-lg-8 offset-xl-1">
          <div className="row">
            <div className="col-sm-3">
              <h6>We Work With</h6>
              <ul>
                <li>
                  <Link to="/delight-users-for-product-development-teams">Product Teams</Link>
                </li>
                <li>
                  <Link to="/go-to-market-for-innovation-teams">Innovation Teams</Link>
                </li>

                <li>
                  <Link to="/scaling-operations-for-customer-success-teams">Operation Teams</Link>
                </li>
              </ul>
            </div>
            <div className="col-sm-4">
              <h6>
                <Link to="/work">Recent Work</Link>
              </h6>
              <ul>
                <li>
                  <Link to="/work/automating-payment-processing-and-payout-splits">
                    Commission System
                  </Link>
                </li>
                <li>
                  <Link to="/work/creating-saas-offering-for-datacenter-provider">
                    Datacenter SaaS Platform
                  </Link>
                </li>
                <li>
                  <Link to="/work/prototyping-new-user-experience">
                    Prototyped Operations App
                  </Link>
                </li>
                <li>
                  <Link to="/work/streamlining-field-workforce">
                    Field Workforce System
                  </Link>
                </li>
                <li>
                  <Link to="/work/optimizing-worker-onboarding">
                    Optimized Onboarding
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-sm-2">
              <h6>
                <Link to="/about">
                  About
                </Link>
              </h6>
              <ul>
                <li>
                  <Link to="/insights">Insights</Link>
                </li>
                <li>
                  <Link to="/partners">Partners</Link>
                </li>
                <li>
                  <Link to="/purpose">Purpose</Link>
                </li>
                <li>
                  <Link to="/about#team">Team</Link>
                </li>
                <li>
                  <a 
                    href="https://www.vaporware.net/careers" 
                    target="_blank"
                    rel="noopener noreferrer">
                    Careers
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-sm-3">
              <h6>Contact</h6>
              <div className="address">
                <h5>
                  <Link to="/locations/raleigh">
                    North Carolina
                  </Link>
                </h5>
                <a 
                  target="_blank" 
                  href="https://g.page/vaporware-raleigh"
                  rel="noopener noreferrer"
                >
                  400 W. Morgan St
                  <br />
                  Suite 204
                  <br />
                  Raleigh, NC 27603
                </a>
                <br />
                <a href="tel:1-919-827-4061">
                  (919) 827-4061
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col offset-lg-4">
          <div className="row justify-content-sm-between flex-row-reverse">
            <div className="social col-sm-5 col-md-4">
              <a
                href="https://github.com/vaporware"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={github} alt="Github" />
              </a>
              <a
                href="https://dribbble.com/vaporware"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={dribble} alt="Dribble" />
              </a>
              <a
                href="https://twitter.com/vpware"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={twitter} alt="Twitter" />
              </a>
              {/* <a
                href="https://www.facebook.com/vaporware"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={facebook} alt="Facebook" />
              </a> */}
              <a
                href="https://www.linkedin.com/company/vaporware"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={linkedin} alt="LinkedIn" />
              </a>
            </div>
            <div className="legal col-sm-7 col-md-8">
              {`© 2013-${new Date().getFullYear()} Vaporware, Inc. All rights reserved. `}
              <Link to="/privacy">Privacy Policy</Link>.
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
